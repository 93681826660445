
export const REVIEWS = [
  {
    emoji: '🍻️',
    rating: 5,
    title: 'Real convenient!',
    text: 'Love this app!! This has been needed! Delivery was on time! The alcohol was still cold, order was complete, nothing missing! Will defo keep on using!',
  },
  {
    emoji: '🏎',
    rating: 5,
    title: 'Got my delivery in 11 minutes',
    text: 'I literally placed my order, blinked and it had arrived, It was so quick. My new favourite app!!',
  },
  {
    emoji: '🦷️',
    rating: 5,
    title: 'Fast test delivery ever',
    text: 'I ordered a toothpaste at 8:38 in the morning and only 7 minutes later I add it right at my door, before going to work!\nNever had such a great experience with any delivery app. Thank you!!',
  },
  {
    emoji: '🚴‍♂️',
    rating: 5,
    title: 'Premier windmill',
    text: 'Brilliant! Absolute life saver I can’t believe they deliver so fast, 15 mins nice polite friendly service and keep you updated,even by personally calling you on the phone. At this moment I am using them more than the big super markets as they seem to be the only service that are able to make sure all their customers needs are met in these hard times.i am disabled and rely on this app. Thank you and Well done!!',
  },
  {
    emoji: '🍿️',
    rating: 5,
    title: 'More convenient than convenience stores',
    text: 'Discovered this little gem today. Online supermarkets are great for tomorrow and nice to order take away meals, but sometimes you’re just ready to watch a movie and forgot the snacks or need another drink or just couldn’t be ar*ed to go out. What a great service that delivers by the time you’ve finished arguing which movie to watch ;-)\nGreat, simple, fast. That’s all I wanted.',
  },
  {
    emoji: '👩‍⚕️️',
    rating: 5,
    title: 'This is a life saver app',
    text: 'I’m a NHS worker and I’ve been unable to purchase things in the shops due to the current COVID19 epidemic and my working hours\nThankfully this came to my rescue as I was able to purchase my essentials and it came in good timing\nThe customer service was helpful, amazing, efficient\nI will be 100% ordering again',
  },
];

export default REVIEWS;