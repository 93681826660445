
export const ORGANIZATION_SCHEMA = {
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "Devo",
  "url": "https://devo.co.uk",
  "description": "Same day grocery delivery from the best local shops. Buy food, drinks, alcohol, cigarettes in three simple steps. Thousands of items delivered in a little as 10 minutes.",
  "logo": "https://devo.co.uk/assets/logo.png",
  "telephone": "+442033895255",
  "sameAs": [
    "https://www.instagram.com/devodelivery/",
    "https://twitter.com/DevoDelivery",
    "https://www.facebook.com/DevoDelivery/",
  ],
  "contactPoint": [{ 
    "@type": "ContactPoint",
    "telephone": "+442033895255",
    "contactType": "customer service"
  }, { 
    "@type": "ContactPoint",
    "email": "support@devo.co.uk",
    "contactType": "customer service"
  }]
};

export const WEBSITE_SCHEMA = {
  "@context": "https://schema.org",
  "@type": "WebSite",
  "name": "Devo",
  "url": "https://devo.co.uk",
};

export const websiteSchemaWithPotentialAction = (potentialAction) => {
  var schema = WEBSITE_SCHEMA;
  if (potentialAction) schema['potentialAction'] = potentialAction;
  return schema;
};

export const IOS_APPLICATION_SCHEMA = {
  "@context": "https://schema.org",
  "@type": "SoftwareApplication",
  "name": "Devo: Grocery delivery",
  "operatingSystem": "iOS 12.0",
  "applicationCategory": "ShoppingApplication",
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.5",
    "ratingCount": "62"
  },
  "offers": {
    "@type": "Offer",
    "price": "0",
    "priceCurrecy": "GBP"
  }
};

export const ANDROID_APPLICATION_SCHEMA = {
  "@context": "https://schema.org",
  "@type": "SoftwareApplication",
  "name": "Devo: Grocery delivery",
  "operatingSystem": "Android 6.0",
  "applicationCategory": "ShoppingApplication",
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.7",
    "ratingCount": "28"
  },
  "offers": {
    "@type": "Offer",
    "price": "0",
    "priceCurrecy": "GBP"
  }
};