import { getAuthToken } from "helper/user";

export const fetchJson = (endpoint: string, path: string, params: any = {}): Promise<any> => {
  return new Promise((resolve, reject) => {
    fetch(generateUrl(endpoint, path, params), {
      headers: {
        'Accept': 'application/json',
        'Devo-Api-Key': process.env.DEVO_API_KEY,
        'Devo-Auth-Token': getAuthToken(),
      }
    })
    .then(res => res.json()).then(json => { 
      resolve(json)
    })
    .catch(err => reject(err));
  });
};

const generateUrl = (endpoint: string, path: string, params: string): string => {
  var base = `${endpoint}${path}`;

  const queryStr = encodeQueryData(params);
  if (queryStr.length > 0)
    base = `${base}?${queryStr}`;

  return base;
};

export const encodeQueryData = (data) => {
  const ret = [];
  for (let d in data) {
    if (data[d]) {
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    }
  }
  return ret.join('&');
};