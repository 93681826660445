import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { withApollo } from 'helper/apollo';
import { SEO } from 'components/seo';
import { Modal } from '@redq/reuse-modal';
import {
  Title,
  Subtitle,
  Subheader,
  Home,
  Header,
  BannerContent,
  Page,
  StepsContainer, 
  BottomHeader,
  BottomOverlay,
  BottomBannerContent,
  Review,
  Area,
  Category,
} from 'styled/home.style';
import SiteFooter from 'components/SiteFooter/SiteFooter';
import LocationSearchBox from 'components/SearchBox/LocationSearchBox';
import HomeStep from 'components/Home/HomeStep';
import HalfHalf from 'components/Home/HalfHalf';

import SimpleStep1 from 'image/home/simple-step-1.png';
import SimpleStep2 from 'image/home/simple-step-2.png';
import SimpleStep3 from 'image/home/simple-step-3.png';
import ProductRainRed from 'image/home/product-rain.jpg';
import AppProductImage2 from 'image/home/app-product-2.png';
import SofaAnimationImage from 'image/home/devo-sofa-animation.jpg';

import { formatCleanPostcode } from 'helper/data/shops';

import { AppBadge } from 'components/SiteFooter/SiteFooter.style';
import Apple from 'image/apple-app-store.svg';
import Google from 'image/google-play-store.svg';
import { iosAppStoreURL, androidAppStoreURL } from 'helper/mobileapp';
import { trackPostcode } from 'helper/gtm/variables';
import TrustBox from 'components/TrustBox/TrustBox';
import { fetchJson } from 'api/api-fetch';
import Log from 'helper/sentry';
import { CATEGORY_ALCOHOL_ROUTE, CATEGORY_CIGARETTE_ROUTE, CATEGORY_ICE_CREAM_ROUTE, CATEGORY_MILK_ROUTE, CATEGORY_SNACK_ROUTE, SHOPS_BY_AREA_ROUTE } from 'constants/navigation';

import { ANDROID_APPLICATION_SCHEMA, IOS_APPLICATION_SCHEMA, ORGANIZATION_SCHEMA, websiteSchemaWithPotentialAction } from 'helper/seo/schemas';
import REVIEWS from 'data/Reviews';
import { SHOPS_SEARCH_ACTION_SCHEMA } from 'helper/seo/search-action-schema';
import { areaNameToAreaId } from 'helper/shops/areaName';

type IndexProps = {
  deviceType: {
    mobile: boolean;
    tablet: boolean;
    desktop: boolean;
  }
}

export const Index: React.FC<IndexProps> = ({ deviceType }) => {
  const router = useRouter();
  const [visibleReviews, setVisibleReviews] = useState(3);
  const [areas, setAreas] = useState([]);

  // ------------------------------
  // API
  // ------------------------------

  useEffect(() => {
    async function fetchAreas() {
      try {
        const response = await fetchJson(process.env.API_SHOP_SERVICE, '/shop/areas');
        if (response) setAreas(Object.keys(response).sort());
      } catch (error) {
        Log.warning('Failed to fetch shop areas', 'home');
      }
    }
    fetchAreas();
  }, []);

  // ------------------------------
  // Actions
  // ------------------------------

  const onSelectPostcode = (postcode: string) => {
    const formatted = formatCleanPostcode(postcode);
    trackPostcode(formatted);
    router.push('/shops/[search]', `/shops/${formatted}`);
  };

  const areaHref = (areaName: string): string => {
    return SHOPS_BY_AREA_ROUTE.replace('[areaName]', areaNameToAreaId(areaName));
  };

  // ------------------------------
  // Render
  // ------------------------------

  type CategoryType = { name: string; emoji: string; url: string; };
  const CATEGORY_LINKS: CategoryType[] = [
    { name: "Ice Cream",  emoji: '🍦', url: CATEGORY_ICE_CREAM_ROUTE },
    { name: "Snacks",     emoji: '🍪', url: CATEGORY_SNACK_ROUTE },
    { name: "Alcohol",    emoji: '🍺', url: CATEGORY_ALCOHOL_ROUTE },
    { name: "Cigarettes", emoji: '💨', url: CATEGORY_CIGARETTE_ROUTE },
    { name: "Milk",       emoji: '🥛', url: CATEGORY_MILK_ROUTE },
  ];

  return (
    <Modal>
      <SEO
        title='Same-day grocery delivery | Order Food, Drinks, Alcohol &amp; Cigarettes | Devo'
        description='Same day grocery delivery from the best local shops. Buy food, drinks, alcohol, cigarettes in three simple steps. Thousands of items delivered in a little as 10 minutes.'
        canonical='/'
        schema={[
          websiteSchemaWithPotentialAction(SHOPS_SEARCH_ACTION_SCHEMA), 
          ORGANIZATION_SCHEMA, 
          IOS_APPLICATION_SCHEMA, 
          ANDROID_APPLICATION_SCHEMA
        ]}
      />

      <Home>
        <Header>
            <BannerContent>
              <Title>Grocery delivery in as little as 10 minutes.</Title>
              <Subtitle>Thousands of items delivered straight to your door.</Subtitle>

              <LocationSearchBox onSelectPostcode={onSelectPostcode} />
              {/* <TrustBox /> */}
            </BannerContent>
        </Header>

        <Page>
          <Subheader className='top'>3 simple steps to order</Subheader>
          <StepsContainer>
            <HomeStep
              image={SimpleStep1}
              title='You Order'
              body='Shop from thousands of products from the best local shops.'
            />
            <HomeStep
              image={SimpleStep2}
              title='We Collect'
              body='One of our trusted delivery drivers collects your order from the store.'
            />
            <HomeStep
              image={SimpleStep3}
              title='We Deliver'
              body='Your order is delivered straight to your door for you to enjoy.'
            />
          </StepsContainer>

          <HalfHalf
            contentLeft
            image={deviceType.desktop ? (
              <video autoPlay loop muted playsInline className='left' >
                <source src='/assets/videos/devo-sofa-animation.webm' type='video/webm'/>
                <source src='/assets/videos/devo-sofa-animation.mp4' type='video/mp4'/>
              </video>
            ) : (
              <img src={SofaAnimationImage} alt="Thousands of your favourite items, on demand" className='left' />
            )}
            title={<Subheader>Thousands of your favourite items, on demand.</Subheader>}
            body={<p>From milk to the snacks and drinks you crave, choose from thousands of your favourite items.</p>}
          />

          <HalfHalf
            image={(
              <img src={AppProductImage2} alt={'Devo iOS and Android application'} className='right'/>
            )}
            title={<Subheader>Same day grocery delivery from shops near you.</Subheader>}
            body={(
              <>
                <p>Whatever you want, we get it. Order and watch in real-time as we deliver your favourite items.</p>
                <div style={{ marginTop: 20 }}>
                  <AppBadge href={`${iosAppStoreURL}?utm_medium=Website&utm_source=Body`}>
                    <img src={Apple} alt='Apple App Store' />
                  </AppBadge>
                  <AppBadge href={`${androidAppStoreURL}&utm_medium=Website&utm_source=Body`}>
                    <img src={Google} alt='Google Play Store' />
                  </AppBadge>
                </div>
              </>
            )}
          />

          <Subheader style={{ textAlign: 'center', margin: '100px 0 20px' }}>Testimonials</Subheader>
          
          <Review.Wrapper>
            {REVIEWS.map((item: any, index: number) => (
              <Review.Card key={index} className={index < visibleReviews ? 'showing' : ''}>
                <Review.Icon>{item.emoji}</Review.Icon>
                <Review.Content>
                  <Review.Stars rating={item.rating} />
                  <Review.Title>{item.title}</Review.Title>
                  <Review.Text>{item.text}</Review.Text>
                </Review.Content>
              </Review.Card>
            ))}
            {visibleReviews < REVIEWS.length &&
              <Review.MoreButton>
                <a onClick={() => setVisibleReviews(REVIEWS.length)}>Read more</a>
              </Review.MoreButton>
            }
          </Review.Wrapper>

          
          <Subheader style={{ margin: '100px 0 20px' }}>Have a craving in mind?</Subheader>
          <Category.Wrapper>
            {CATEGORY_LINKS.map((category, index) =>
              <Category.Item key={index} href={category.url}>
                <p>
                  <span>{category.emoji}</span>
                  {category.name}
                </p>
              </Category.Item>
            )}
          </Category.Wrapper>

          {areas.length > 0 &&
            <>
              <Subheader style={{ margin: '75px 0 20px' }}>Popular Areas</Subheader>
              <Area.Wrapper>
                <Area.Grid>
                  {areas.map(areaName => 
                    <Area.Link>
                      <a href={areaHref(areaName)}>{areaName}</a>
                    </Area.Link>
                  )}
                </Area.Grid>
              </Area.Wrapper>
            </>
          }
        </Page>

        <BottomHeader image={ProductRainRed}>
          <BottomOverlay>
            <BottomBannerContent>
              <Title>Ready to order?</Title>
              <Subtitle>Order online with Devo to get your favourite brands delivered straight to your door.</Subtitle>
              <LocationSearchBox onSelectPostcode={onSelectPostcode} />
            </BottomBannerContent>
          </BottomOverlay>
        </BottomHeader>
      </Home>

      <SiteFooter />
    </Modal>
  )
}

export default withApollo(Index);
